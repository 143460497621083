import { RFValue } from "react-native-responsive-fontsize";

export default {
  COLORS: {
    ALERT: "#ec1414",
    ICON: '#FFA920', //COR DOS CADEADOS E DAS SETAS PARA O LADO
    ICON_HEADER_PURCHASE: "#3A383A", //COR DAS SETINHAS 
    BACKGROUND_HEADER: "none", //COR DE TODOS OS CABEÇALHOS
    //TELA CLICKCOURSE, PLANS e SIGNUP
    ICON_HEADER: "#3A383A",  //COR DA PALAVRA ESCOLHER COMPRA, CADASTRAR-SE E COMPLETAR PAGAMENTO e VOLTAR da EDITPROFILE

    //CORES GERAIS
    PRIMARY_900: "#d7823f", //COR MAIS ESCURA DO CLIENTE = COR PRINCIPAL DOS DETALHES DO APP - CHECKBOX, FECHAR TERMOS DE USO, ETC 
    PRIMARY_800: "#FFA920", //COR INTERMEDIÁRIA DO CLIENTE PARA INPUT LOGIN E ACTIVITY INDICATOR
    PRIMARY_700: "#F8F8F8", //COR MAIS CLARA DO CLIENTE = COR PRINCIPAL MAIS CLARA PARA INPUT LOGIN E ACTIVITY INDICATOR
    TEXT_BUTTON: "#FFFFFF", //USADA PARA O TEXTO DOS BOTÕES LOGIN, CLICKCOURSE, SIGNUP - COR DE FUNDO É A PRIMARY_900

    //CORES DO DRAWER
    DRAWER_SELECTED: "#d7823f", //COR DRAWER CLICADO E DO TEXTO DO DRAWER

    //TELAS: LOGIN, CLICKCOURSE, SIGNUP E PLANS
    BACKGROUND_ABOUT: "#F8F8F8", //COR PARA A ABOUT, DRAWER, CLICK COURSE, PLANS, EDITPROFILE, LOGIN E CADASTRO
    TITLE_ABOUT: '#3A383A', //TAMANHOS EXTRABIG, BIG E STANDARD
    TEXT_ABOUT: '#3A383A', //TAMANHOS MEDIUM, SMALL E EXTRASMALL

    //TELA TERMOS DE USO
    BACKGROUND_TERMS: "#FFFFFF", //COR PARA O FUNDO DOS TERMOS
    
    //TELA MAIN
    ICON_HEADER_CLICKCLASS: "#d7823f", //COR DO ÍCONE VOLTAR DA CLICKCLASS
    ICON_HEADER_MAIN: "white", //COR DO ICONE DRAWER
    BACKGROUND_MAIN: "#796753", //COR PARA A MAIN E CLICKCLASS
    TITLE_OVERPHOTO_MAIN: "white", //USADA PARA O TÍTULO E SUBTÍTULO DA MAIN
    TITLE_MAIN: "white", //USADA PARA PARA OS TAMANHOS EXTRABIG, BIG E STANDARD
    TEXT_MAIN: "white", //USADA PARA PARA OS TAMANHOS MEDIUM, SMALL E EXTRASMALL

    //FOOTER TELA ABOUT E DRAWER
    BACKGROUND_FOOTER: "#3A383A", //COR DO RODAPÉ
    TEXT_FOOTER: "white", //USADA PARA O TEXTO DO RODAPÉ

    GRADIENT_MAIN: [
      'rgba(255,255,255,0)',
      'rgba(255,255,255,0)',
      'rgba(0,0,0,0.1)',
      '#796753'
    ],

    //BOTÕES ARQUIVAR E PUBLICAR
    BACKGROUND_PUBLISH: "#FFA920", //COR DO RODAPÉ
    BACKGROUND_ARCHIVE: "#3A383A", //COR DO RODA
    TEXT_PUBLISH: "#FFFFFF", //USADA PARA O TEXTO DO RODAPÉ
    TEXT_ARCHIVE: "#FFFFFF", //USADA PARA O TEXTO DO RODAPÉ
  },

  FONTFAMILY: {
    BOLD: "text_Bold",
    MEDIUM: "text_Medium",
    REGULAR: "text_Regular",
    LIGHT: "text_Light",
    TITLE: "text_Title",
    SUBTITLE: "text_Subtitle",
    TEXTS: "text_Texts"
  },
  FONTSIZE: {
    EXTRABIG: RFValue(30, 980), //USADA 1X NA COURSES
    BIG: 24,
    STANDARD: 20,
    MEDIUM: 18,
    SMALL: 16,
    EXTRASMALL: 13,
  },
};
